// export const ANIMATION_COLORS=['#cad2c5', '#84a98c', '#52796f', '#354f52', '#2f3e46']
export const ANIMATION_COLORS = [
  "#C0C0C0",
  "#808080",
  "#A9A9A9",
  "#848484",
  "#3a3a3a",
];
export const STAR_COLOR = [
  "#c0c0c0",
  "#3a3a3a",
  "#d946ef",
  "#7a6ded",
  "#591885",
  "#ec4899",
  "#f97316",
];
